import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Contact.css";
import Base from "../../Base/Base";
import Footer from "../../Footer/Footer";
import apiUrl from "../../../Config";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "scam_or_fraud", // Default value
    message: "",
    ad_id: "",
  });

  const [status, setStatus] = useState(null);
  const [statusType, setStatusType] = useState(""); // To track success or error

  useEffect(() => {
    // Extract ad_id from query parameters
    const params = new URLSearchParams(window.location.search);
    const adId = params.get('ad_id');
    if (adId) {
      setFormData((prevData) => ({
        ...prevData,
        ad_id: adId,
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.message.length < 20) {
      setStatus("The message should contain more than 20 characters.");
      setStatusType("error");

      // Hide the error message after 5 seconds
      setTimeout(() => {
        setStatus(null);
      }, 5000);
      return;
    }

    try {
      await axios.post(`${apiUrl}/api/contact/`, formData);
      setStatus("Thank you for reaching out! We will get back to you soon.");
      setStatusType("success");
      setFormData({
        name: "",
        email: "",
        subject: "scam_or_fraud", // Reset to default value
        message: "",
        ad_id: "",
      });

      // Hide the success message after 5 seconds
      setTimeout(() => {
        setStatus(null);
      }, 5000);
    } catch (error) {
      setStatus("There was an error submitting the form. Please try again.");
      setStatusType("error");

      // Hide the error message after 5 seconds
      setTimeout(() => {
        setStatus(null);
      }, 5000);
    }
  };

  return (
    <>
    <div className="contactmain-body">
      <Base />
      <div style={{ marginTop: "120px" }}></div>

      <div className="contact-us-container">
        <div className="contact-us">
          <h2>Contact Us</h2>
          {status && (
            <p className={`status-message ${statusType === "success" ? "status-success" : "status-error"}`}>
              {status}
            </p>
          )}

          <form onSubmit={handleSubmit}>
            <label>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              <select
                name="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleChange}
                required
              >
                <option value="scam_or_fraud">Scam or Fraud</option>
                <option value="billing">Billing</option>
                <option value="account">Account</option>
                <option value="general">General</option>
              </select>
            </label>

            {/* Conditionally render the ad_url input based on the subject */}
            {formData.subject === "scam_or_fraud" && (
              <label>
                <input
                  name="ad_id"
                  placeholder="Ad ID"
                  value={formData.ad_id}
                  onChange={handleChange}
                  required
                />
              </label>
            )}

            <label>
              <textarea
                placeholder="Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </label>
            <button className="contact-button" type="submit">
              Sumbit
            </button>
          </form>
        </div>
      </div>
      <div className="about-footer">
        <Footer />
      </div>
      </div>
    </>
  );
}

export default Contact;
