import React, { useState, useEffect } from "react";
import { Container, Card, Spinner, Pagination } from "react-bootstrap";
import moment from "moment";
import Base from "../Base/Base";
import PostDetailModel from "../PostDetailModel/PostDetailModel";
import apiUrl from "../../Config";
import axios from "axios";
import "../SearchResults/SearchResults.css";
import Footer from "../Footer/Footer";
import { useLocation } from "react-router-dom";
import NoResultImg from "../../Assets/Search.png";

function SearchResults() {
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPost, setSelectedPost] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [previousUrl, setPreviousUrl] = useState(null);
  const location = useLocation();
  const [searchPerformed, setSearchPerformed] = useState(false);

  // Function to handle post click
  const handlePostClick = (post) => {
    setSelectedPost(post);
    setShowModal(true);
  };

  // Handle close modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPost(null);
  };

  // Function to get adjacent posts
  const getAdjacentPosts = (currentPostId) => {
    const currentIndex = searchResults.findIndex(
      (post) => post.id === currentPostId
    );
    const prevPost = currentIndex > 0 ? searchResults[currentIndex - 1] : null;
    const nextPost =
      currentIndex < searchResults.length - 1
        ? searchResults[currentIndex + 1]
        : null;
    return { prevPost, nextPost };
  };

  // Function to handle search query
  const handleSearch = async () => {
    if (searchQuery.trim() === "") {
      console.log("Search query is empty, exiting search.");
      return;
    }

    console.log("Starting search for query:", searchQuery); // Log the query before the search begins
    setLoading(true);
    setSearchPerformed(false);

    try {
      // Construct the full URL with the query parameters
      const url = `${apiUrl}/api/ads/search/?query=${encodeURIComponent(
        searchQuery
      )}`;

      console.log("Full URL for API request:", url); // Print the full URL with query

      // Sending request to API
      const response = await axios.get(url);

      console.log("Received response from API:", response); // Log the full API response
      console.log("Search results:", response.data.results); // Log the actual search results
      setSearchResults(response.data.results);
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 results per page
      setCurrentPage(new URLSearchParams(url.split("?")[1]).get("page") || 1);
      setSearchPerformed(true);
    } catch (err) {
      console.error("Error occurred during API call:", err); // Log any error caught in the try-catch
      setError("An error occurred while fetching the ads.");
    } finally {
      console.log("Search complete. Loading state set to false.");
      setLoading(false);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search).get("query");
    if (query && query !== searchQuery) {
      setSearchQuery(query);
    }
  }, [location.search]);

  useEffect(() => {
    if (searchQuery) {
      handleSearch();
    }
  }, [searchQuery]);

  // Pagination calculation
  // const currentPosts = searchResults.slice(
  //   (currentPage - 1) * postsPerPage,
  //   currentPage * postsPerPage
  // );
  const handlePageChange = (url) => {
    if (url) handleSearch(url);
  };

  return (
    <>
      <Base />
      <div style={{ marginTop: "170px" }}></div>
      <Container className="posts-container">
        {loading ? (
          <Spinner animation="border" />
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : searchResults.length > 0 ? (
          searchResults.map((ad) => (
            <div className="postcard-container">
            <Card
              key={ad.id}
              className="post-item mb-3"
              onClick={() => handlePostClick(ad)}
            >
              <Card.Body className="d-flex align-items-start newupcard">
                <div className="profile-info">
                  <img
                    src={`${apiUrl}${ad.user.profile_photo}`}
                    alt={`${ad.user.username}'s profile`}
                    className="profile-photo"
                  />
                  {/* <span className="username">{ad.user.username}</span> */}
                </div>
                <div className="card-content flex-grow-1 ml-3 searchresulcontent">
                  <Card.Title>{ad.title}</Card.Title>
                  <Card.Text className="post-city">
                      <i className="fas fa-map-marker-alt"></i> {ad.city}
                    </Card.Text>
                  <Card.Text className="card-des">
                    {ad.description.length > 40
                      ? ad.description.substring(0, 40) + "..."
                      : ad.description}
                  </Card.Text>

                  <div className="uds-details">
                    <Card.Text className="date-post">
                      {moment(ad.created).fromNow()}
                    </Card.Text>
                    <Card.Text className="ad-username">
                      posted by {ad.user.username}
                    </Card.Text>
                  </div>
                </div>
              </Card.Body>
            </Card>
            </div>
          ))
        ) : searchPerformed ? ( // Check if search was performed and no results were found
          <div>
            <img src={NoResultImg} alt="No Results Found" />
            <p className="nopostresult">No Search results found.</p>
          </div>
        ) : null}

        <Pagination>
          {previousUrl && (
            <Pagination.Prev onClick={() => handlePageChange(previousUrl)} />
          )}
          {[...Array(totalPages)].map((_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => {
                const url = `${apiUrl}/api/ads/search/?query=${encodeURIComponent(
                  searchQuery
                )}&page=${index + 1}`;
                handleSearch(url);
              }}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          {nextUrl && (
            <Pagination.Next onClick={() => handlePageChange(nextUrl)} />
          )}
        </Pagination>

        {selectedPost && (
          <PostDetailModel
            show={showModal}
            handleClose={handleCloseModal}
            post={selectedPost}
            prevPost={getAdjacentPosts(selectedPost.id).prevPost}
            nextPost={getAdjacentPosts(selectedPost.id).nextPost}
          />
        )}
      </Container>
      <div className="about-footer">
        <Footer />
      </div>
    </>
  );
}

export default SearchResults;
