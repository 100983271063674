import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './TopMessageBar.css'; // Import your CSS file
import apiUrl from '../../../Config';
import CloseButton from 'react-bootstrap/CloseButton';

const TopMessageBar = ({ onClose }) => {
  // State to hold the ad data and custom styles
  const [ad, setAd] = useState({ text: '', url: '' });
  const [customStyles, setCustomStyles] = useState({ bgColor: '', textColor: '', font: '', fontSize: '' });

  // Fetch the custom styles
  const getCustomStyles = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/custom-styles/`);
      console.log("API Response for styles:", response.data[0]);
  
      const customStyleData = response.data[0]; // Assuming you want the first object
      if (customStyleData) {
        // Map API response properties to your state
        const bgColor = customStyleData.bg_color || '';
        const textColor = customStyleData.text_color || '';
        const font = customStyleData.font || '';
        const fontSize = customStyleData.font_size || '';
  
        // Log the mapped properties
        console.log("Background Color:", bgColor || "Not provided");
        console.log("Text Color:", textColor || "Not provided");
        console.log("Font:", font || "Not provided");
        console.log("Font Size:", fontSize || "Not provided");
  
        // Update state
        setCustomStyles({
          bgColor,
          textColor,
          font,
          fontSize,
        });
      }
    } catch (error) {
      console.error('Error fetching custom styles:', error);
    }
  };

  // Function to fetch top ads
  const getTopAds = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/admin-top-ads/`);
      const adData = response.data[0]; // Assuming you want the first ad
      if (adData) {
        setAd({
          text: adData.ad_text || '',
          url: adData.url || '',
        });
      }
    } catch (error) {
      console.error('Error fetching the top ad:', error);
    }
  };

  useEffect(() => {
    getCustomStyles();
    getTopAds();
  }, []); 



  const handleClose = () => {
    setAd({ text: '', url: '' }); // Clear ad
    onClose();

  };

  // Do not render the bar if there's no ad text
  if (!ad.text) return null;

  return (
    <div
      className="top-message-bar"
      style={{
        backgroundColor: customStyles.bgColor , // Default to white if undefined
        color: customStyles.textColor,        // Default to black
        fontFamily: customStyles.font, // Default font
        fontSize: customStyles.fontSize,         // Default size
      }}
    >
      <a
  href={ad.url} 
  className="ad-link " 
  target="_blank" 
  rel="noopener noreferrer" 
  style={{ color: customStyles.textColor }}
>
  {ad.text}
</a>

  
  <span onClick={handleClose} className="adclose" style={{ color: customStyles.textColor }} >
        <CloseButton />
      </span>
    </div>
  );
};

export default TopMessageBar;
