// PasswordResetConfirm.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import apiUrl from '../../Config';
import "../Password/Password.css";
import Footer from '../Footer/Footer';
import Base from '../Base/Base';

const PasswordResetConfirm = () => {
  const { uidb64, token } = useParams();
  console.log(uidb64, token)
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [message, setMessage] = useState('');
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        // Make the POST request including uid and token
        await axios.post(`${apiUrl}/api/password/reset/confirm/${uidb64}/${token}/`, {
            new_password1: password1,
            new_password2: password2,
            uid: uidb64,  // Include uid in the request body
            token: token   // Include token in the request body
        });
        setMessage('Password has been reset.');
        <Navigate to='/login' replace={true} />
    } catch (error) {
        if (error.response) {
            // Log and display the exact error from the server
            console.error("Error resetting password:", error.response.data);
            setMessage(error.response.data.detail || 'Error resetting password.');
        } else {
            console.error("Error resetting password:", error.message);
            setMessage('Error resetting password.');
        }
    }
};

  return (
    <>

    <div className='resetbody'>
      <Base />
      <div style={{ marginTop: "290px" }}></div>

      <form className='resetconfirmform' onSubmit={handleSubmit}>
        <h3>Change Password</h3>
      {message && <p>{message}</p>}

        <input
          type="password"
          value={password1}
          onChange={(e) => setPassword1(e.target.value)}
          placeholder="New password"
          className='password1field'
          required
        />
        <input
          type="password"
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
          placeholder="Confirm new password"
          className='password2field'

          required
        />
        <button className='reset-button' type="submit">Reset Password</button>
      </form>

      <div className="pass-footer">
        <Footer />
      </div>
      </div>
    </>
  );
};

export default PasswordResetConfirm;
