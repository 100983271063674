import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Loginnew.css";
import { Container, Form, Image, Button, Alert } from "react-bootstrap";
import klogo from "../../Assets/letter-k.png";
import "@fontsource/poppins";
import { Link } from "react-router-dom";
import GoogleLogo from "../../Assets/g-icon.png";
import EyeIcon from "../../Assets/eye.png";
import EyeSlashIcon from "../../Assets/eye-slash.png";
import apiUrl from "../../Config";

import Base from "../Base/Base";
import Footer from "../Footer/Footer";
import Loading from "../Loading/Loading";
const Eye = () => <img src={EyeIcon} alt="Eye Icon" />;
const EyeSlash = () => <img src={EyeSlashIcon} alt="Eye Slash Icon" />;



const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  function getCookie(name) {
    const cookieValue = document.cookie.match(
      "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
    );
    return cookieValue ? cookieValue.pop() : null;
  }

  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    try {
      const csrftoken = getCookie("csrftoken");

      const response = await axios.post(
        `${apiUrl}/api/customlogin/`,
        {
          username,
          password,
        },
        {
          headers: {
            "X-CSRFToken": csrftoken,
          },
        }
      );

      if (response.data.is_blocked) {
        setErrorMessage("Your account is currently blocked. Please contact the administration for assistance.");
        setSuccessMessage("");
        return; // Stop further processing
      }

      localStorage.setItem("accessToken", response.data.access);
      localStorage.setItem("refreshToken", response.data.refresh);
      localStorage.setItem("pk", response.data.user.pk);

      setSuccessMessage("");
      setErrorMessage("");

      setTimeout(() => {
        navigate("/home");
      }, 2000);
    } catch (error) {
      // Handle errors from the API
      if (error.response && error.response.data) {
          const errorResponse = error.response.data;

          // Check if errorResponse is an array and handle accordingly
          if (Array.isArray(errorResponse)) {
              setErrorMessage(errorResponse.join(", ")); // Join the messages if it's an array
          } else if (errorResponse.detail) {
              setErrorMessage(errorResponse.detail); // Catch general error messages
          } else if (errorResponse.non_field_errors) {
              setErrorMessage(errorResponse.non_field_errors.join(", "));
          } else {
              setErrorMessage("Login failed. Please try again.");
          }
      } else {
          setErrorMessage("An unexpected error occurred.");
      }
      setSuccessMessage("");
  } finally {
      setLoading(false);
  }
};
  return (
    <>
  
     <Container className="loginpage-whole-container">
      {loading && <Loading />}
      <Base />
      <div style={{ marginTop: "95px" }}></div>
      <Container className="main-container" style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
        <Container className="left-container">
          <Image
            src={klogo}
            classname="lo-im"
            alt="Logo"
            width="350"
            height="350"
          />
        </Container>
        <Container className="right-container">
          <div className="login-99">
            <h2 className="login-header-text">Login Here</h2>
          {/* <button className="google-button-container" type="button" onClick={() => {
    window.location.href = `${apiUrl}/api/auth/google/`;
  }}>
              <img
                className="button-content-aPF"
                src={GoogleLogo}
                alt="Google Logo"
              />
              <p className="sign-in-with-google-GG5">Sign in with Google</p>
            </button> */}
            {/* <div className="separator-container">
              <span className="separator-line"></span>
              <span className="separator-text">or</span>
              <span className="separator-line"></span>
            </div> */}

            <Container>
            {successMessage && (
                <Alert variant="success" className="alert-success">
                  {successMessage}
                </Alert>
              )}
              {errorMessage && (
                <Alert variant="danger" className="alert-danger">
                  {errorMessage}
                </Alert>
              )}
              <Form.Control
                type="text"
                placeholder="Username"
                className="custom-input"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <div className="password-input-container">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="custom-input password-input"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div
                  className="eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeSlash /> : <Eye />}
                </div>
              </div>
              <div className="f-text">
      <span className="forgot-pass">
        <Link to="/password-reset-request">Forgot your password?</Link>
      </span>
    </div>

              <Button variant="light" className="login" onClick={handleLogin}>
                Login
              </Button>

              <br />

              <div className="button-w65" id="15:317">
                <Link to="/signup" className="button-w65-sub-0">
                  Don’t have an account?{" "}
                  <span className="button-w65-sub-1">Sign up</span>
                </Link>
              </div>
            </Container>
          </div>
          <div className="c-text">
          <span className="b-text-sub-1">By Proceeding, I agree </span>
          <span className="different-color-text">T&C, Privacy Policy</span>
        </div>
        </Container>
       
      </Container>
      <div className="loginpage-footer">
        <Footer />
      </div>
     
      </Container>
    </>
  );
};

export default Login;
