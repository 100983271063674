import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Carousel } from 'react-bootstrap'; // Import Carousel component
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import apiUrl from '../../../Config'; // Adjust the path as necessary

const HomeImgAd = () => {
    const [homePageAds, setHomePageAds] = useState([]);

    useEffect(() => {
        const fetchHomePageAds = async () => {
            try {
                const token = localStorage.getItem("authAToken");
                const response = await axios.get(`${apiUrl}/api/admin-home-page-ads/`);
                setHomePageAds(response.data);
            } catch (error) {
                console.error("There was an error fetching the HomePageAd data!", error);
            }
        };

        fetchHomePageAds();
    }, []);


    const firstAd = homePageAds.length > 0 ? homePageAds[0] : null;

    return (
      <>
        <div className="homepage-ads-container">
            {firstAd ? (
                <div key={firstAd.id} className="homepage-ad-item">
                    {/* <h3>Ad created on {new Date(firstAd.created).toLocaleDateString()}</h3> */}
                    <Carousel className={`adimg-photos ${firstAd.ad_photos.length === 2 ? 'two-images' : firstAd.ad_photos.length === 3 ? 'three-images' : ''}`}>
                        {firstAd.ad_photos.map(photo => (
                            <Carousel.Item key={photo.id}>
                                <img src={photo.ad_file} alt="Ad" className="d-block w-100 adimg-photo" />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            ) : (
                <p>Advertise your product here</p>
            )}
        </div>
    </>
    );
};

export default HomeImgAd;
