import React, { useEffect, useState } from "react";
import apiUrl from "../../../Config";
import axios from "axios";
import { Modal, Button, Card, Spinner } from "react-bootstrap"; // Import Bootstrap components
import AdDetailModal from "../AdDetailModel/AdDetailModel";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import '../Dashboard/Dashboard.css'; // Import custom styles

function Newdash() {
  const [verifiedAds, setVerifiedAds] = useState([]);
  const [notVerifiedAds, setNotVerifiedAds] = useState([]);
  const [selectedOption, setSelectedOption] = useState("NotVerifiedAds");
  const [selectedAd, setSelectedAd] = useState(null); // State to hold the clicked ad details
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [nextUrl, setNextUrl] = useState(null);
  const [previousUrl, setPreviousUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false); 

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setCurrentPage(1); // Reset to page 1 when switching between verified and not verified ads
  };

  const fetchVerifiedAds = async (page = 1) => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get(`${apiUrl}/api/ads/verified/?page=${page}`);
      setVerifiedAds(response.data.results);
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 ads per page
      setCurrentPage(page); // Update the current page
    } catch (error) {
      console.error("Error fetching verified ads:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const fetchNotVerifiedAds = async (page = 1) => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get(`${apiUrl}/api/ads/not-verified/?page=${page}`);
      setNotVerifiedAds(response.data.results);
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 ads per page
      setCurrentPage(page); // Update the current page
    } catch (error) {
      console.error("Error fetching not verified ads:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handlePageChange = (direction) => {
    if (direction === "next" && nextUrl) {
      selectedOption === "NotVerifiedAds" ? fetchNotVerifiedAds(currentPage + 1) : fetchVerifiedAds(currentPage + 1);
    } else if (direction === "previous" && previousUrl) {
      selectedOption === "NotVerifiedAds" ? fetchNotVerifiedAds(currentPage - 1) : fetchVerifiedAds(currentPage - 1);
    }
  };

  useEffect(() => {
    selectedOption === "NotVerifiedAds" ? fetchNotVerifiedAds(currentPage) : fetchVerifiedAds(currentPage);
  }, [selectedOption, currentPage]);

  const handleAdClick = (ad) => {
    setSelectedAd(ad);
    setShowModal(true);
  };

  const renderContent = () => {
    const ads = selectedOption === "NotVerifiedAds" ? notVerifiedAds : verifiedAds;
    return (
      <div className={`${selectedOption}-ads-wrapper`}>
        <h1 className="verified-ads-header">{selectedOption === "NotVerifiedAds" ? "Unverified Ads" : "Verified Ads"}</h1>
        {loading ? (
          <div className="spinner-wrapper">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            {ads.length > 0 ? (
              <div className="ads-card-deck">
                {ads.map((ad, index) => (
                  <Card key={index} onClick={() => handleAdClick(ad)} className="ad-card">
                    <Card.Body className="ad-card-body">
                      <Card.Title className="ad-card-title">{ad.title}</Card.Title>
                      <Card.Text className="ad-card-text">
                        {ad.description.length > 100 ? `${ad.description.substring(0, 100)}...` : ad.description}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            ) : (
              <p className="no-results">No ads available.</p>
            )}
          </>
        )}
        <div className="pagination-controls d-flex align-items-center justify-content-center my-3">
          <Button
            variant="outline-primary"
            onClick={() => handlePageChange("previous")}
            disabled={currentPage === 1}
            className="me-2"
          >
            <FaChevronLeft />
          </Button>
          <span className="mx-2">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            variant="outline-primary"
            onClick={() => handlePageChange("next")}
            disabled={currentPage === totalPages}
            className="ms-2"
          >
            <FaChevronRight />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="dashboard-container">
        <div className="sidebar-employee">
          <button onClick={() => handleOptionClick("NotVerifiedAds")} className={`sidebar-button-em ${selectedOption === "NotVerifiedAds" ? "active" : ""}`}>
           Unverified Ads
          </button>
          <button onClick={() => handleOptionClick("VerifiedAds")} className={`sidebar-button-em ${selectedOption === "VerifiedAds" ? "active" : ""}`}>
            Verified Ads
          </button>
        </div>
        <div className="main-content">
          {renderContent()}
        </div>
      </div>
      <AdDetailModal ad={selectedAd} show={showModal} onClose={() => setShowModal(false)} />
    </>
  );
}

export default Newdash;
