import React, { useEffect, useState } from "react";
import axios from "axios";
import apiUrl from "../../Config";
import { useParams, useNavigate } from "react-router-dom";
import { Card, Container, Spinner, Pagination } from "react-bootstrap";
import moment from "moment";
import Base from "../Base/Base";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Footer from "../Footer/Footer";
import "../Posts/Posts.css";
import Select from "react-select";
import NoResultImg from "../../Assets/Search.png";
import PostDetailModel from "../PostDetailModel/PostDetailModel";
import { Form, Dropdown, ListGroup, ListGroupItem } from "react-bootstrap";
import defaultProfilePhoto from '../../Assets/placeholder.jpg';

function Posts() {
  const { subCategory1Id, subCategory } = useParams();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [previousUrl, setPreviousUrl] = useState(null);
  const navigate = useNavigate();
  const [subCategories1, setSubCategories1] = useState([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedRadius, setSelectedRadius] = useState("");

  // const getCurrentLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         setLocation({ latitude, longitude });
  //         console.log("User's Location:", { latitude, longitude });
  //       },
  //       (error) => {
  //         console.error("Error getting location:", error);
  //         setError("Unable to retrieve your location.");
  //       }
  //     );
  //   } else {
  //     console.log("Geolocation is not supported by this browser.");
  //     setError("Geolocation not supported.");
  //   }
  // };

  const handlePostClick = (post) => {
    console.log("Navigating to post:", post);
    setSelectedPost(post);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPost(null);
  };

  const handlePageChange = (url) => {
    if (url) fetchPosts(url);
  };

  const mainCategoryId = localStorage.getItem("selectedMainCategory");

  const fetchSubCategoriesForPost = async (categoryId) => {
    try {
      console.log("Fetching subcategories for Main Category ID:", categoryId);
      const url = `${apiUrl}/api/main-categories/${categoryId}/`;
      console.log("Fetching subcategories from URL:", url);

      const response = await axios.get(url);
      console.log("API Response:", response);

      // Assuming response.data contains the subcategories
      setSubCategories1(response.data.SubCategories);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      setError(error);
    }
  };

  useEffect(() => {
    console.log(
      "Selected Main Category ID from local storage:",
      mainCategoryId
    );
    if (mainCategoryId) {
      fetchSubCategoriesForPost(mainCategoryId); // Fetch subcategories using the ID from local storage
    }
  }, [mainCategoryId]);

  const fetchPosts = async (url) => {
    setLoading(true);
    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        setPosts(response.data.results);
        setNextUrl(response.data.next);
        setPreviousUrl(response.data.previous);
        setTotalPages(Math.ceil(response.data.count / 10));
        setCurrentPage(new URLSearchParams(url.split("?")[1]).get("page") || 1);
      } else {
        console.error("Failed to fetch posts. Status code:", response.status);
      }
    } catch (err) {
      console.error("Error fetching posts:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedCity = localStorage.getItem("selectedCity");
    console.log("city", storedCity);
    let url = `${apiUrl}/api/posts/bysubcategory1/${subCategory1Id}/`;

    // Add city as a query parameter if it is selected
    if (storedCity) {
      url += `?city=${encodeURIComponent(storedCity)}`;
    }
    if (selectedRadius) {
      url += `&radius=${selectedRadius}`;
    }

    if (subCategory1Id) {
      // Print the complete URL
      console.log("Complete URL:", url);
      fetchPosts(url);
    }
  }, [subCategory1Id, selectedCity, selectedRadius]);

  const handleSubCategoryClick = (subCategoryId, subCategory) => {
    // Navigate to the posts for the selected subcategory
    navigate(
      `/posts/${mainCategoryId}/${subCategoryId}/${encodeURIComponent(
        subCategory
      )}`
    );
  };

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await import("../CityNameList.json"); // Import the JSON
        const cityData = response.default || response;

        // Map the array of city names (strings) to the required format
        setCities(
          cityData.map((city) => ({
            label: city, // For display in the dropdown
            value: city, // For selection value
          }))
        );
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();

    const storedCity = localStorage.getItem("selectedCity");
    if (storedCity) {
      setSelectedCity({ label: storedCity, value: storedCity }); // Set the saved city as selected
    }
  }, []);

  const handleCityChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedCity(selectedOption); // Set the selected city in state
      localStorage.setItem("selectedCity", selectedOption.value); // Save the city to localStorage
      console.log(`Selected city: ${selectedOption.label}`);
    } else {
      setSelectedCity(null);
      localStorage.removeItem("selectedCity"); // Remove the city from localStorage
      console.log("City selection cleared");
    }
  };

  const handleRadiusChange = (event) => {
    const value = event.target.value;
    setSelectedRadius(value);
    console.log(`Selected radius: ${value}`);
  };

  return (
    <>
      <Base />
      <div style={{ marginTop: "120px" }}></div>
      <Container className="posts-main-container">
        <Container className="sidebarposts">
          {/* City Selection Dropdown */}
          <Form.Group controlId="formCity" className="ad-ci-po">
            <Form.Label className="cus-la-po">City</Form.Label>
            <Select
              options={cities}
              onChange={handleCityChange}
              placeholder={selectedCity ? selectedCity.label:"Chennai"}
              isClearable
              isSearchable
            />
          </Form.Group>
          <Form.Group controlId="formRadius">
            <Form.Label className="cus-la-po">Select radius</Form.Label>
            <Form.Select
              className="custom-select"
              onChange={handleRadiusChange}
              defaultValue=""
            >
              {/* <option value="" disabled></option> */}
              <option value="0">0 km</option>
              <option value="5">5 km</option>
              <option value="10">10 km</option>
              <option value="15">15 km</option>
              <option value="25">25 km</option>
            </Form.Select>
          </Form.Group>

          <hr />
          {subCategories1 && subCategories1.length > 0 ? (
            <ListGroup as="ol">
              {subCategories1.map((subCategory) => (
                <ListGroupItem
                  key={subCategory.id}
                  onClick={() =>
                    handleSubCategoryClick(
                      subCategory.id,
                      subCategory.SubCategory
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  {subCategory.SubCategory}
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : (
            <p>No subcategories available.</p>
          )}
        </Container>
        <Container className="posts-container">
          <div className="nav-text">
            <a href="/Home">Home</a> &gt; {subCategory}
          </div>
          {loading ? (
            <Spinner animation="border" />
          ) : posts.length > 0 ? (
            posts.map((post) => (
              <div className="postcard-container" key={post.id}>
                <Card
                  className="post-item mb-3"
                  onClick={() => handlePostClick(post)}
                >
                  <Card.Body className="d-flex align-items-start">
                    <div className="profile-info">
                      <img
                        src={post.user.profile_photo || defaultProfilePhoto}
                        alt={post.user.profile_photo ? `${post.user.username}'s profile` : 'Default profile image'}
                        className="profile-photo"
                      />
                    </div>
                    <div className="card-content flex-grow-1 ml-3">
                      <Card.Title>{post.title}</Card.Title>
                      <Card.Text className="post-city">
                        <i className="fas fa-map-marker-alt"></i> {post.city}
                      </Card.Text>
                      <Card.Text className="card-des">
                        {post.description.length > 40
                          ? post.description.substring(0, 45) + "..."
                          : post.description}
                      </Card.Text>
                      <div className="uds-details">
                        <Card.Text className="date-post">
                          {moment(post.created).fromNow()}
                        </Card.Text>
                        <Card.Text className="ad-username">
                          posted by {post.user.username}
                        </Card.Text>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))
          ) : (
            <div>
              <img src={NoResultImg} alt="No Results Found" />
              <p className="nopostresult">No posts available.</p>
            </div>
          )}
          {selectedPost && (
            <PostDetailModel
              show={showModal}
              handleClose={handleCloseModal}
              post={selectedPost}
            />
          )}
          <div className="pagination">
            <Pagination>
              {previousUrl && (
                <Pagination.Prev
                  onClick={() => handlePageChange(previousUrl)}
                />
              )}
              {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => {
                    const url = `${apiUrl}/api/posts/bysubcategory1/${subCategory1Id}/?page=${
                      index + 1
                    }`;
                    fetchPosts(url);
                  }}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              {nextUrl && (
                <Pagination.Next onClick={() => handlePageChange(nextUrl)} />
              )}
            </Pagination>
          </div>
        </Container>
      </Container>
      <div className="gomeabout-footer">
        <Footer />
      </div>
    </>
  );
}

export default Posts;

{
  /* <div className="carousel-container">
                    <Carousel className="files-ca">
                      {post.files.map((file, index) => (
                        <Carousel.Item key={index}>
                          <img
                            className="d-block w-100 carousel-image"
                            src={file.file}
                            alt={`Slide ${index + 1}`}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div> */
}
