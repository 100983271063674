import React, { useState, useEffect } from "react";
import axios from "axios";
import "../ADash/ADash.css";
import apiUrl from "../../../Config";
import moment from "moment";
import {
  Carousel,
  Card,
  Button,
  Form,
  Table,
  Modal,
  Pagination,
} from "react-bootstrap";
import { Col, Row, Spinner } from "react-bootstrap";
import Loading from "../../Loading/Loading";
import AdminDetailModal from "../../Employee/AdminDetailModel/AdminDetailModel";

function ADash({ userId }) {
  const [selectedOption, setSelectedOption] = useState("Users");
  const [homePageAds, setHomePageAds] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [topAds, setTopAds] = useState([]);
  const [newAdText, setNewAdText] = useState("");
  const [newAdUrl, setNewAdUrl] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [fontStyle, setFontStyle] = useState("");
  const [fontSize, setFontSize] = useState("");
  const [customStylesList, setCustomStylesList] = useState([]);
  const [vdAds, setVdAds] = useState([]);
  const [newAdImage, setNewAdImage] = useState(null);
  const [newVideoAd, setNewVideoAd] = useState(null);
  const [newAdImages, setNewAdImages] = useState([]);
  const [userData, setUserData] = useState([]);
  const [maxPostLimit, setMaxPostLimit] = useState(10);
  const [error, setError] = useState(null);
  const [userPrivatePosts, setUserPrivatePosts] = useState([]);
  const [showPostsModal, setShowPostsModal] = useState(false);
  const [nextUrl, setNextUrl] = useState(null);
  const [previousUrl, setPreviousUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);
  const [showAdminDetailModal, setShowAdminDetailModal] = useState(false);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/users/`);
      console.log(response.data.results);
      setUserData(response.data.results);
    } catch (error) {
      console.error("There was an error fetching the User Details");
    }
  };

  const fetchHomePageAds = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/admin-home-page-ads/`);
      console.log("geting homepage ads", response.data);
      setHomePageAds(response.data);
    } catch (error) {
      console.error("There was an error fetching the HomePageAd data!", error);
    }
  };

  const fetchTopAds = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/admin-top-ads/`);
      console.log(response.data);
      setTopAds(response.data);
    } catch (error) {
      console.error(
        "There was an error fetching the TopNavigationAd data!",
        error
      );
    }
  };

  const deleteAd = async (id) => {
    try {
      await axios.delete(`${apiUrl}/api/admin-top-ads/${id}/`);
      setTopAds(topAds.filter((ad) => ad.id !== id));
    } catch (error) {
      console.error("There was an error deleting the ad!", error);
    }
  };

  const createAd = async () => {
    console.log("Creating ad with:", newAdText, newAdUrl);
    try {
      const response = await axios.post(`${apiUrl}/api/admin-top-ads/`, {
        ad_text: newAdText,
        url: newAdUrl,
      });
      console.log("Response from backend:", response.data);
      setTopAds([...topAds, response.data]);
      setNewAdText("");
      setNewAdUrl("");
    } catch (error) {
      console.error("There was an error creating the ad!", error);
    }
  };

  const createCustomStyle = async () => {
    // Renamed function to avoid conflict
    try {
      const response = await axios.post(`${apiUrl}/api/custom-styles/`, {
        bg_color: bgColor,
        text_color: textColor,
        font_style: fontStyle,
        font_size: fontSize,
      });

      // Add the newly created style to the list
      setCustomStylesList((prevStyles) => [...prevStyles, response.data]);

      // Reset form fields
      setBgColor("#ffffff");
      setTextColor("#000000");
      setFontStyle("normal");
      setFontSize("16px");

      console.log("Custom style created:", response.data);
    } catch (error) {
      console.error("Error creating custom style:", error);
    }
  };

  const createHomePageAd = async () => {
    if (!newAdImages || newAdImages.length === 0) return; // Check if any images are selected

    const formData = new FormData();

    // Loop through the selected images and append each one
    for (let i = 0; i < newAdImages.length; i++) {
      formData.append("files", newAdImages[i]); // Append each file to the FormData
    }

    try {
      const response = await axios.post(
        `${apiUrl}/api/admin-home-page-ads/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Response from server:", response.data);
      // Check if response.data is an array or a single object
      if (Array.isArray(response.data)) {
        setHomePageAds([...homePageAds, ...response.data]); // Append the new ads to the existing list
      } else {
        setHomePageAds([...homePageAds, response.data]); // Append the single ad object
      }

      setNewAdImages([]); // Clear the input after submission
    } catch (error) {
      // Enhanced error handling
      if (error.response) {
        // The request was made, but the server responded with a status code
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made, but no response was received
        console.error("Error request:", error.request);
      } else {
        // Something happened in setting up the request
        console.error("Error message:", error.message);
      }
    }
  };

  const deleteHomePageAd = async (id) => {
    try {
      await axios.delete(`${apiUrl}/api/admin-home-page-ads/${id}/`);
      setHomePageAds(homePageAds.filter((ad) => ad.id !== id));
    } catch (error) {
      console.error("There was an error deleting the HomePageAd!", error);
    }
  };

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Check if this cookie string begins with the name we want
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const createVideoAd = async () => {
    if (!newVideoAd) return;

    const formData = new FormData();
    formData.append("ad_video", newVideoAd);
    const csrftoken = getCookie("csrftoken");
    try {
      const response = await axios.post(
        `${apiUrl}/api/admin-videos-ads/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRFToken": csrftoken,
          },
        }
      );
      setVdAds([...vdAds, response.data]);
      setNewVideoAd(null);
    } catch (error) {
      console.error("There was an error creating the VideoAd!", error);
    }
  };

  const deleteVideoAd = async (id) => {
    try {
      await axios.delete(`${apiUrl}/api/admin-video-ads/${id}/`);
      setVdAds(vdAds.filter((ad) => ad.id !== id));
    } catch (error) {
      console.error("There was an error deleting the VideoAd!", error);
    }
  };

  const fetchVideoAds = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/admin-videos-ads/`);
      setVdAds(response.data);
    } catch (error) {
      console.error("There was an error fetching the VideoAd data!", error);
    }
  };

  const handleFreePostLimitInputChange = (e) => {
    setMaxPostLimit(e.target.value);
  };

  const changeFreePostLimit = async () => {
    try {
      const response = await axios.put(`${apiUrl}/api/max-post-limit/`, {
        max_post_limit: maxPostLimit,
      });
      // Optionally, check the response
      console.log("Update Response:", response.data);
    } catch (error) {
      console.error("Error details:", error); // Log error details for debugging
      setError("Error updating maximum post limit");
    }
  };

  const fetchFreePostLimitData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/max-post-limit/`);
      console.log(response.data.max_post_limit);
      setMaxPostLimit(response.data.max_post_limit);
    } catch (error) {
      console.error("Error details:", error);
      setError("Error fetching maximum post limit");
    }
  };

  const fetchUserPrivatePosts = async (userId, page) => {
    setLoading(true);
    try {
      const page = currentPage || 1;
      console.log("Fetching posts for User ID:", userId, "Page:", page);
      const response = await axios.get(
        `${apiUrl}/api/adsuser/${userId}/?page=${page}`
      ); // Adjust the URL based on your API
      console.log("User Posts:", response.data.results);
      setNextUrl(response.data.next);
      console.log(response.data.next);
      console.log(response.data.previous);

      setPreviousUrl(response.data.previous);
      setUserPrivatePosts(response.data.results || []);
      setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 ads per page
      setCurrentPage(page); // Assuming you have a state to store posts
      setShowPostsModal(true); // Show the modal to display posts
    } catch (error) {
      console.error("Error fetching user posts:", error);
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  useEffect(() => {
    if (userId) {
      // Ensure userId is available
      fetchUserPrivatePosts(userId, currentPage); // Fetch posts on userId or page change
    }
  }, [userId, currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage); // Trigger useEffect to refetch posts
    }
  };

  const fetchEmployeeList = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/employeeslist/`);
      console.log(response.data.results);
      setEmployeeList(response.data.results);
    } catch (error) {
      setError("Error fetching employee list ");
    }
  };

  useEffect(() => {
    if (selectedOption === "HomePageAd") {
      fetchHomePageAds();
    } else if (selectedOption === "TopNavigationAd") {
      fetchTopAds();
    } else if (selectedOption === "VideoAd") {
      fetchVideoAds();
    } else if (selectedOption === "Users") {
      fetchUserData();
    } else if (selectedOption === "Employees") {
      fetchEmployeeList();
    } else if (selectedOption === "PostLimit") {
      fetchFreePostLimitData();
      changeFreePostLimit();
    }
  }, [selectedOption]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleAdClick = (post) => {
    setSelectedAd(post); // Set the clicked ad
    setShowAdminDetailModal(true); // Show the modal
  };

  // Close modal handler
  const handleCloseAdDetailModal = () => {
    setShowAdminDetailModal(false);
    setSelectedAd(null);
  };

  const BlockUnblockUser = async (userId, isBlocked) => {
    try {
      const authToken = localStorage.getItem("accessToken");

      // Set is_blocked to true if currently not blocked, otherwise set to false
      const response = await axios.put(
        `${apiUrl}/api/userdetail/${userId}/`,
        { is_blocked: !isBlocked }, // Toggle the block status
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        alert(
          `User has been ${isBlocked ? "unblocked" : "blocked"} successfully`
        );

        // Update the local userData state
        setUserData((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId ? { ...user, is_blocked: !isBlocked } : user
          )
        );
      } else {
        setError("Failed to block or unblock user");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to block or unblock user");
    }
  };

  const renderContent = () => {
    switch (selectedOption) {
      case "Users":
        return (
          <>
            <h2 className="heading-admin">Users</h2>
            <Table
              striped
              bordered
              hover
              variant="dark"
              className="custom-table"
            >
              <thead>
                <tr>
                  <th>User ID</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Status</th> {/* New column for block/unblock button */}
                  <th>Action</th> {/* New column for action buttons */}
                </tr>
              </thead>
              <tbody>
                {userData.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>
                      <span
                        className="adminpage-user-click"
                        onClick={() => fetchUserPrivatePosts(user.id)}
                      >
                        {user.username}
                      </span>
                    </td>
                    <td>{user.email}</td>
                    <td>{user.is_blocked ? "Blocked" : "Active"}</td>
                    <td>
                      <Button
                        variant={user.is_blocked ? "success" : "danger"}
                        onClick={() =>
                          BlockUnblockUser(user.id, user.is_blocked)
                        }
                      >
                        {user.is_blocked ? "Unblock" : "Block"}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {error && <p className="error-message">{error}</p>}
          </>
        );
      case "Employees":
        return (
          <>
            <h2 className="heading-admin">Employees</h2>
            <Table
              striped
              bordered
              hover
              variant="dark"
              className="custom-table"
            >
              <thead>
                <tr>
                  {/* <th>Profile Photo</th> */}
                  <th>User ID</th>
                  <th>Username</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {employeeList.map((user) => (
                  <tr key={user.id}>
                    {/* <td>
                {user.profile_photo && (
                  <img
                    src={user.profile_photo}
                    alt={`${user.username}'s profile`}
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                    }}
                  />
                )}
              </td> */}
                    <td>{user.id}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        );
      case "TopNavigationAd":
        return (
          <div className="top-nav-tex-container">
            <h2 className="heading-admin">Top Navigation Ad</h2>
            <input
              type="text"
              value={newAdText}
              onChange={(e) => setNewAdText(e.target.value)}
              placeholder="Enter new ad text"
              className="custom-input"
            />
            <input
              type="url"
              value={newAdUrl}
              onChange={(e) => setNewAdUrl(e.target.value)}
              placeholder="Enter URL for the ad"
              className="custom-input"
              required
              pattern="https?://.*" // Optional: Ensures URL starts with http:// or https://
              title="Please enter a valid URL (e.g., https://www.example.com)"
            />

            <button className="top-nav-but" onClick={createAd}>
              Create New Ad
            </button>
            <div>
              <div>
                <input
                  type="color"
                  value={bgColor}
                  onChange={(e) => setBgColor(e.target.value)}
                />
                <input
                  type="color"
                  value={textColor}
                  onChange={(e) => setTextColor(e.target.value)}
                />
                <select
                  value={fontStyle}
                  onChange={(e) => setFontStyle(e.target.value)}
                >
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                  <option value="oblique">Oblique</option>
                  <option value="bold">Bold</option>
                </select>
                <input
                  type="text"
                  value={fontSize}
                  onChange={(e) => setFontSize(e.target.value)}
                  placeholder="Font size (e.g., 16px)"
                />
                <button onClick={createCustomStyle}>Create Custom Style</button>

                <div>
                  {customStylesList.map((style, index) => (
                    <div
                      key={index}
                      style={{
                        backgroundColor: style.bg_color,
                        color: style.text_color,
                        fontStyle: style.font_style,
                        fontSize: style.font_size,
                      }}
                    >
                      Custom Style {index + 1}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {topAds.map((ad) => (
              <Card key={ad.id} className="top-nav-ad-show-card-details">
                <Card.Body className="top-nav-ad-show-card-details-text">
                  {ad.ad_text}
                  
                    <a href={ad.url} target="_blank" rel="noopener noreferrer">
                      {ad.url}
                    </a>
                  
                  <Button
                    variant="danger"
                    className="but-home-page-ad"
                    onClick={() => deleteAd(ad.id)}
                  >
                    Delete
                  </Button>
                </Card.Body>
                <span className="crea-top-nav-con">
                  {moment(ad.created).format("MMMM Do YYYY, h:mm:ss a")}
                </span>
              </Card>
            ))}
          </div>
        );
      case "HomePageAd":
        return (
          <div className="homepage-ad-adm-container">
            <h2 className="heading-admin">Home Page Ad</h2>
            <input
              type="file"
              onChange={(e) => setNewAdImages(Array.from(e.target.files))}
              accept="image/*"
              className="custom-file-adm-container"
              multiple
            />
            <button
              className="homepage-ad-button-admn-conatiner"
              onClick={createHomePageAd}
            >
              Create New Image Ad
            </button>
            {homePageAds.map((ad) => (
              <Card key={ad.id} className="homepage-ad-card-adm-container">
                <Card.Body className="homepage-ad-card-body-container">
                  <Carousel>
                    {ad.ad_photos.map((photo) => (
                      <Carousel.Item key={photo.id}>
                        <img
                          src={photo.ad_file}
                          alt="Home Ad"
                          className="ad-media"
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>

                  <Button
                    className="new-but-homepage-ad"
                    variant="danger"
                    onClick={() => deleteHomePageAd(ad.id)}
                  >
                    Delete
                  </Button>
                  <span className="crea-top-nav-con">
                    {moment(ad.created).format("MMMM Do YYYY, h:mm:ss a")}
                  </span>
                </Card.Body>
              </Card>
            ))}
          </div>
        );
      case "VideoAd":
        return (
          <div className="homepage-ad-adm-container">
            <h2 className="heading-admin"> Video Ad</h2>

            <input
              type="file"
              onChange={(e) => setNewVideoAd(e.target.files[0])}
              accept="video/*"
              className="custom-file-adm-container"
            />
            <button
              className="homepage-ad-button-admn-conatiner"
              onClick={createVideoAd}
            >
              Create New Video Ad
            </button>
            {vdAds.map((ad) => (
              <Card key={ad.id} className="homepage-ad-card-adm-container">
                <Card.Body className="homepage-ad-card-body-container">
                  <video className="ad-media" width="100%" controls>
                    <source src={ad.ad_video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <button
                    className="new-but-homepage-ad"
                    variant="danger"
                    onClick={() => deleteVideoAd(ad.id)}
                  >
                    Delete
                  </button>
                  <span className="crea-top-nav-con">
                    {moment(ad.created).format("MMMM Do YYYY, h:mm:ss a")}
                  </span>
                </Card.Body>
              </Card>
            ))}
          </div>
        );
      case "PostLimit":
        return (
          <div className="postlimit-ad-adm-container">
            <h2 className="heading-admin"> Post Limit</h2>

            <div className="sub-admin">
              Maximum Post Limit Free Ads : {maxPostLimit}
            </div>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                changeFreePostLimit();
              }}
            >
              <Form.Group controlId="maxPostLimit">
                <Form.Control
                  type="number"
                  value={maxPostLimit}
                  onChange={handleFreePostLimitInputChange}
                  min={1} // Ensuring that the value is at least 1
                  required
                />
              </Form.Group>
              <Button
                className="postlimit-ad-button-admn-conatiner"
                variant="primary"
                type="submit"
              >
                Update Limit
              </Button>
              {error && <div style={{ color: "red" }}>{error}</div>}
            </Form>
          </div>
        );

      default:
        return <div>Select an option from the sidebar</div>;
    }
  };

  return (
    <>
      <Modal show={showPostsModal} onHide={() => setShowPostsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>User Private Posts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Loading />
          ) : (
            <>
              {userPrivatePosts.length ? (
                <div className="user-posts">
                  {userPrivatePosts.map((post) => (
                    <Card
                      className="post-card"
                      key={post.id}
                      style={{ marginBottom: "16px" }}
                      onClick={() => handleAdClick(post)} // Open modal with the selected ad
                    >
                      <Card.Body>
                        <Card.Title className="post-title">
                          {post.title}
                        </Card.Title>
                        <Card.Text className="post-description">
                          {post.description}
                        </Card.Text>
                        <Card.Text className="post-created">
                          {new Date(post.created).toLocaleString()}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  ))}
                </div>
              ) : (
                <p>No private posts available for this user.</p>
              )}

              {/* Pagination Controls */}
              <div className="pagination">
                <Pagination>
                  {previousUrl && (
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                    />
                  )}
                  {[...Array(totalPages)].map((_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  {nextUrl && (
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                    />
                  )}
                </Pagination>
              </div>

              {/* Render AdDetailModal */}
              {showAdminDetailModal && selectedAd && (
                <AdminDetailModal
                  ad={selectedAd}
                  show={showAdminDetailModal}
                  onClose={handleCloseAdDetailModal} // Pass handler to close modal
                />
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
      <div className="dashboard-container">
        <div className="sidebar-employee">
          <button
            onClick={() => handleOptionClick("Users")}
            className={`sidebar-button-em ${
              selectedOption === "Users" ? "active" : ""
            }`}
          >
            Users
          </button>
          <button
            onClick={() => handleOptionClick("Employees")}
            className={`sidebar-button-em ${
              selectedOption === "Employees" ? "active" : ""
            }`}
          >
            Employees
          </button>
          <button
            onClick={() => handleOptionClick("TopNavigationAd")}
            className={`sidebar-button-em ${
              selectedOption === "TopNavigationAd" ? "active" : ""
            }`}
          >
            Top Navigation Ad
          </button>
          <button
            onClick={() => handleOptionClick("HomePageAd")}
            className={`sidebar-button-em ${
              selectedOption === "HomePageAd" ? "active" : ""
            }`}
          >
            Home Page Ad
          </button>
          <button
            onClick={() => handleOptionClick("VideoAd")}
            className={`sidebar-button-em ${
              selectedOption === "VideoAd" ? "active" : ""
            }`}
          >
            Video Ad
          </button>
          <button
            onClick={() => handleOptionClick("PostLimit")}
            className={`sidebar-button-em ${
              selectedOption === "PostLimit" ? "active" : ""
            }`}
          >
            Post Limit
          </button>
        </div>
        <div className="main-content">{renderContent()}</div>
      </div>
    </>
  );
}

export default ADash;
