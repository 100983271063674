import React from "react";
import "./Footer.css";
import x from "../../Assets/X icon.png";
import f from "../../Assets/f icon.png";
import i from "../../Assets/insta icon.png";
import ContactUs from "../FooterContent/Contact/Contact";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logos">
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <img src={x} alt="Twitter" className="footer-logo" />
          </a>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <img src={f} alt="Facebook" className="footer-logo" />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <img src={i} alt="Instagram" className="footer-logo" />
          </a>
        </div>

        <div className="footer-links">
         <a href="/Home">Home</a>
          <a href="/About">About</a>
          <a href="/Locations">Locations</a>
          <a href="/Term">Terms of Use</a>
          <a href="/Contact">Contact Us</a>
          <a href="/Careers">Careers</a>
        </div>

        <p className="foot-words">Copyright © 2024 Klassifies. All rights reserved.</p>

      <p>
        Made with <FontAwesomeIcon icon={faHeart} style={{ color: 'red' }} /> by Klassifies
      </p>

      </div>
    </footer>
  );
}

export default Footer;
