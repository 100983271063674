import React, { useState, useEffect } from "react";
import { Modal, Carousel, Button, ModalFooter } from "react-bootstrap";
import moment from "moment";
import "./PostDetailModel.css";
import { useNavigate } from "react-router-dom";
import Base from "../Base/Base";
import defaultProfilePhoto from '../../Assets/placeholder.jpg';

const PostDetailModel = ({ show, handleClose, post }) => {
  const navigate = useNavigate();
  const [currentPost, setCurrentPost] = useState(post);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (post) {
      console.log("Initial post set:", post);
      setCurrentPost(post);
    }
  }, [post]);

  if (!currentPost) return null;

  const handleReport = () => {
    navigate(`/Contact?ad_id=${currentPost.id}`);
  };

  const handleUnlockClick = () => {
    navigate("/login");
  };

  console.log("Current Post:", currentPost);
  console.log("Previous Ad:", currentPost.previous_ad);
  console.log("Next Ad:", currentPost.next_ad);

  function obfuscateText(text) {
    return text
      .split("")
      .map((char) => "*")
      .join("");
  }

  document.addEventListener("contextmenu", (event) => event.preventDefault());

  const accessToken = localStorage.getItem("accessToken");
  return (
    <>
      <Base />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="profile-info-postsmodel">
              <img
                src={currentPost.user.profile_photo || defaultProfilePhoto }
                alt={`${currentPost.user.username}'s profile`}
                className="profile-photo-postsmodel"
              />
              <span className="username">{currentPost.user.username}</span>
              <span className="c-title">{currentPost.user.created}</span>

            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="post-detail">
            <div className="details">
              <Button
                variant="danger"
                className="ml-auto report-button"
                onClick={handleReport}
              >
                Report
              </Button>
              <p>
                <strong>Ad ID:</strong> {currentPost.id}
              </p>
              <p>
                <strong>Title:</strong> {currentPost.title}
              </p>
              <p>
                <strong>Description:</strong> {currentPost.description}
              </p>
              <div
                className="unlock-container"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <div className="details">
                  <p>
                    <strong>Mobile:</strong>
                    <span className="unlock-box">
                      {accessToken ? (
                        currentPost.mobile
                      ) : (
                        <>
                          {currentPost.mobile.slice(0, 2)}
                          <span className="obfuscated-text">
                            {obfuscateText(currentPost.mobile.slice(2))}
                          </span>
                        </>
                      )}
                    </span>
                  </p>
                  <p>
                    <strong>Address:</strong>
                    <span className="unlock-box">
                      {accessToken ? currentPost.street : "*******"}
                    </span>
                  </p>
                </div>
                {!accessToken && hovered && (
                  <button
                    className="unlock-button"
                    onClick={handleUnlockClick}
                  >
                    Unlock Details
                  </button>
                )}
              </div>
              <p className="lispac">
                <strong>City:</strong> {currentPost.city}
              </p>
              <p>
                <strong>Pincode:</strong> {currentPost.pincode}
              </p>
              <p>
                <strong>State:</strong> {currentPost.state}
              </p>
              <p className="created-date">
                <strong>Posted:</strong> {moment(currentPost.created).fromNow()}
              </p>
            </div>
            {currentPost.files && currentPost.files.length > 0 && (
              <div className="cust-carousel">
             <Carousel indicators={false} controls={true}>
                {currentPost.files.map((file, index) => (
                  <Carousel.Item key={index}>
                    <img
                      className="carou-image"
                      src={file.file}
                      alt={`Slide ${index + 1}`}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};


      {/* <ModalFooter>
      <Button 
    variant="secondary" 
    onClick={() => handleNavigate(currentPost.previous_ad)}
    disabled={!currentPost.previous_ad}
  >
    {currentPost.previous_ad ? Previous Ad: ${currentPost.previous_ad.title} : 'No Previous Ad'}
  </Button>
  <Button 
    variant="secondary" 
    onClick={() => handleNavigate(currentPost.next_ad)}
    disabled={!currentPost.next_ad}
  >
    {currentPost.next_ad ? Next Ad: ${currentPost.next_ad.title} : 'No Next Ad'}
  </Button>
      </ModalFooter> */}
   
export default PostDetailModel;